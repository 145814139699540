<template>
    <middleware-sidebar-outline
      :uuid="uuid"
      :middlewareID="middlewareID"
      :middlewareType="middlewareType"
      @close="$emit('close')"
      @shown="$emit('shown'); editorKey = editorKey + 1 "
      @saveMiddleware="buildAgents"
      v-model="variables"
    >
      <div class="mt-1" v-if="oracleQueryData.connection">
        <div v-for="pos in renderCustomInputList" :key="pos">
          <custom-input
            v-if="oracleQueryData[pos] != undefined && variables"
            label="Destiny"
            :id="`custom-input-${pos}`"
            class="my-50"
            :possibleValues="variables"
            v-model.trim="oracleQueryData[pos].register_1"
            advanced
          >
            <template #label>
              <span class="text-capitalize">
                {{ pos }} 
              </span>
            </template>
          </custom-input>
          <div v-else class="mb-2">
            <b-skeleton class="mb-25" width="25%" height="12px"/>
            <b-skeleton class="mb-25" width="100%" height="37px"/>
            <b-skeleton class="mb-25" width="25%" height="14px"/>
          </div>
        </div>
        <b-row no-gutters>
          <b-col cols="12" class="pl-1 pr-2">
            <connection-register-input class="my-50" :connectionTypeID="8" v-model="oracleQueryData.connection"/>
          </b-col>

        </b-row>
        <b-col cols="12">
          <div v-if="oracleQueryData && oracleQueryData.query && oracleQueryData.query.register_1">
            <label for="prism-editor">Query <helper-tooltip size="13" innerText="query sql" tooltipPlacement="right"/></label>
              <monaco
                :key="'editor-query-'+editorKey"
                language="mysql"
                height="600px"
                v-model="oracleQueryData.query.register_1.value"
              />
          </div>
        </b-col>
     
      </div>
    </middleware-sidebar-outline> 
    </template>
    
    <script>
    import { 
      BPopover,
      BIcon,
      BButton,
      BRow,
      BCol,
      BInputGroup,
      BInputGroupAppend,
      BFormInput,
      BContainer,
      BSidebar,
      BSpinner,
      BTabs,
      BTab,
      BCardText,
      BCardBody,
      BCard,
      BAvatar,
      BSkeleton,
      BFormCheckbox,
      BCollapse,
    } from 'bootstrap-vue'
    import { v4 as uuidv4 } from 'uuid'
    
    import VuePerfectScrollbar from "vue-perfect-scrollbar";
    import VariablesPanel from "@/views/pages/middleware/MiddlewareManagement/Components/VariablesPanel.vue";
    import { makeToast } from '@/layouts/components/Popups'
    import Source from '@/custom/class/Agent/Source'
    import ActionsReorderList from '@/layouts/components/Transmission/Middleware/Agent/ActionsReorderList.vue'
    import HelperTooltip from '@/layouts/components/HelperTooltip'
    import Sources from '@/custom/class/Enum/Sources.js'
    import VSelect from 'vue-select'
    import DefaultAgent from '@/layouts/components/Transmission/Middleware/Agent/DefaultAgent';
    import SwitchInput from '@/views/pages/middleware/MiddlewareManagement/Components/SwitchRegisterInput.vue'
    
    
    import { PrismEditor } from 'vue-prism-editor';
    import 'vue-prism-editor/dist/prismeditor.min.css'; // import the styles somewhere
    
    import { highlight, languages } from 'prismjs/components/prism-core';
    import 'prismjs/components/prism-markup.js';
    
    import 'prismjs/components/prism-sql.js';
    import 'prismjs/themes/prism-tomorrow.css';
    import MiddlewaresMixin from '@/views/pages/middleware/MiddlewareManagement/Components/MiddlewareSidebar/MiddlewaresMixin.js'
    import CustomInput from "@/views/pages/middleware/MiddlewareManagement/Components/CustomInput.vue";
    import ConnectionRegisterInput from '@/views/pages/middleware/MiddlewareManagement/Components/ConnectionRegisterInput.vue'
    
    import Middlewares from "@/custom/class/Enum/Middlewares.js"
    import MiddlewareSidebarOutline from "@/views/pages/middleware/MiddlewareManagement/Components/MiddlewareSidebar/MiddlewareSidebarOutline.vue"
    import * as MiddlewareFunctions from  "@/views/pages/middleware/MiddlewareManagement/Components/MiddlewareSidebar/MiddlewareFunctions.js"
    import Monaco from "@/layouts/components/editor/monaco.vue"
    
      export default {
        mixins: [MiddlewaresMixin],
    
        components: {
          BPopover,
          BSidebar,
          BIcon,
          BButton,
          BRow,
          BCol,
          BInputGroup,
          BInputGroupAppend,
          BFormInput,
          BContainer,
          VuePerfectScrollbar,
          VariablesPanel,
          BSpinner,
          BTabs,
          BTab,
          BCardText,
          BCard,
          BAvatar,
          BCardBody,
          ActionsReorderList,
          PrismEditor,
          CustomInput,
          BSkeleton,
          HelperTooltip,
          VSelect,
          BFormCheckbox,
          BCollapse,
          SwitchInput,
          ConnectionRegisterInput,
          MiddlewareSidebarOutline,
          Monaco, 
        },
        props: {
          i18nKey: {
            type: String,
            default: "oracle_query"
          },
          middlewareID: {
            type: Number,
            required: true,
          },
          uuid: {
            type: String,
            required: true,
          },
          value: {
            type: Array,
            required: true,
          }
        },
        data() {
          return {
            uuidMap: {},
            variables: undefined,
            variant_map: undefined,
    
            agentList: [],
            isSaving: false,
            version: 0,
            editorKey: 0,
            middlewareType: new Middlewares().items.find(el=> el.id == 60),
    
            renderCustomInputList: [
              // 'username',
              // 'password',
              // 'host',
              // 'database',
            ],
    
            oracleQueryData: {
              // username: undefined,
              // password: undefined,
              // database: undefined,
              // host: undefined,
              query: undefined,
              // tls: undefined,
              // certificate: undefined,
              connection: undefined
            },
    
            fieldname: {
              // username: 'USERNAME',
              // password: 'PASSWORD',
              // database: 'DATABASE',
              // host: 'HOST',
              query: 'QUERY',
              // tls: 'TLS',
              // certificate: 'CERTIFICATE',
              connection: 'CONNECTION'
            },
    
            defaultValue: {
              username: '',
              password: '',
              database: '',
              host: '',
              query: '',
              tls: 'false',
              certificate: '',
              connection: ''
            },
    
            defaultSource: {
              username: 7,
              password: 7,
              database: 7,
              host: 7,
              query: 7,
              tls: 7,
              certificate: 7,
              connection: 7
            },
    
            registerInUse: [
              'register_1',
              'register_destiny'
            ],
    
            defaultAgentActionID: 3, 
            defaultAgentBlockID: 1,
    
          }
        },
        computed: {
          agents: {
            get() {
              return this.value
            },
            set(value) {
              this.$emit('input', value)
            }
          },
          sourceOptions() {
            return new Sources().items
          },
        },
        mounted() {
          this.init();
        },
        methods: {
          init() {
            this.initializeAgents();
          },
          highlighter(code) {
            return highlight(code, languages.sql); // languages.<insert language> to return html with markup
          },
          configureVars() {
            this.variant_map = this.$refs[this.getID("variables-panel")].getVariantMap();
            this.variables = this.$refs[this.getID("variables-panel")].getAllVars();
          },
          isObject(item) {
            return item instanceof Object && !(item instanceof Array);
          },
          isArray(item) {
            return item instanceof Array;
          },
          buildAgents() {
            this.isSaving = true;
            
            Object.keys(this.oracleQueryData).forEach((pos) => {
              this.agentList.push(DefaultAgent.defineToAPI(this.oracleQueryData[pos], this.middlewareID));
            })
            this.saveAgents();
          },
          saveAgents() {
            this.$store
              .dispatch("saveAgents", {
                agentList: this.agentList,
                transmissionID: this.$route.params.transmissionID,
              })
              .then((response) => {
                makeToast({
                  title: this.$t("agent.toast.create_agents.success.title"),
                  text: this.$t("agent.toast.create_agents.success.message"),
                  variant: "success",
                  icon: "CheckIcon",
                });
                
                this.$emit("saved", response.data);
              })
              .catch((error) => {
                this.isSaving = false;
              });
          },
          initializeAgents() {
            Object.keys(this.oracleQueryData).forEach((pos) => {
              this.oracleQueryData[pos] = MiddlewareFunctions.getValueFrom(
                {
                  default: DefaultAgent.setAgent(this, {
                    id: null,
                    enum_agent_action_id: this.defaultAgentActionID,
                    enum_agent_block_id: this.defaultAgentBlockID,
                    register_1: {
                      source: 7,
                      value: this.defaultValue[pos]
                    },
                    register_2: {},
                    register_destiny: {
                      source: 30,
                      value: this.fieldname[pos],
                    },
                    execution_order: 1,
                    fatal_on_fail: true,
                  }),
                  source: 30,
                  value: this.fieldname[pos],
                  from: {
                    value: "register_destiny",
                    source: "register_destiny",
                  },
                  modifier: false,
                  multiple: false,
                  expected: {
                    source: "7",
                    from: "register_1",
                  },
                },
                this.agents
              );
            })
            if (this.oracleQueryData.query.register_1.value.length < 10){
              this.oracleQueryData.query.register_1.value += "\n\n\n\n\n\n\n\n\n\n\n\n\n\n"
            }
          },
          getID(key) {
            if (this.uuidMap[key]) {
              return this.uuidMap[key];
            }
    
            const uuid = uuidv4();
            this.uuidMap[key] = uuid;
    
            return uuid;
          },
          sidebarShown() {
    this.$emit("shown")
            this.isSaving = false;
    
            let el = document.getElementsByTagName("html");
            el[0].classList.add("hide-scrollbar");
          },
          sidebarHidden() {
            this.$emit("close");
    
            let el = document.getElementsByTagName("html");
            el[0].classList.remove("hide-scrollbar");
          },
          toggleSidebar() {
            this.$root.$emit("bv::toggle::collapse", this.uuid);
          },
          copyCertificateToClipboard(){
            let certificate = this.oracleQueryData.certificate.register_1.value
            navigator.clipboard.writeText(certificate)
            makeToast({
              title: this.$t("common.toast.copy_to_clipboard.success.title"),
              text: this.$t("common.toast.copy_to_clipboard.success.message"),
              variant: "success",
              icon: "CheckIcon",
            });
          }
        }
    
      }
    </script>
    
    <style lang="scss" scoped>
    
      .sidebar-container{
        max-height: 97vh !important;
        height: fit-content !important;
        overflow-y: auto !important;
      }
      
      .sidebar-fixed-header{
        position: sticky !important;
        top: 0 !important;
        z-index: 2;
      }
      
      .sidebar-content{
        position: relative !important;
        padding-bottom: 20px;
        margin-bottom: 70px !important;
      }
      
    </style>
    
    <style lang="scss" scoped>
    .agent-text {
      display: block !important;
      width: 300px;
    }
    
    .my-editor {
      background: #161d31;
      color: #ccc;
    
      font-family: Fira code, Fira Mono, Consolas, Menlo, Courier, monospace;
      font-size: 14px;
      line-height: 1.5;
      padding: 5px;
    
      pre, textarea {
        background: none !important;
        border: none !important;
        outline: none !important;
    
        &:focus, &:active, &:focus-visible {
          border: none !important;
          outline: none !important;
        }
      }
    }
    
    .height-500 {
      height: 500px;
    }
    
    </style>
    <style lang="scss">
    .bg-default,
    .b-sidebar-header {
      background-color: #151925 !important;
    }
    
    .agent-sidebar {
      .b-sidebar-right {
        border-left: solid 1px #0d111c !important;
      }
    
      .b-sidebar-body {
        overflow: hidden !important;
      }
    
      .control-height {
        position: relative;
        overflow: auto;
    
        min-height: 100%;
        padding-bottom: 50px;
      }
    
      .saving-adjust-text-position {
        position: relative;
        top: 2px;
      }
    }
    
    </style>